.thumbnailBtn {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: var(--border-radius-base);

    &::after {
        position: absolute;
        content: '';
        top: -110px;
        left: 0;
        inline-size: 280px;
        block-size: 152px;
        border-radius: var(--border-radius-base);

        @media (--screen-lt-lg) {
            inline-size: 238px;
            block-size: 148px;
            top: -105px;
        }
        @media (--screen-lt-md) {
            inline-size: 432px;
            block-size: 150px;
            top: -108px;
        }
        @media (--screen-lt-sm) {
            inline-size: calc(100vw - var(--space-48));
        }
    }
}

.youtubeVideo {
    inline-size: 95vw;
    max-inline-size: 1088px;
    aspect-ratio: 16 / 9;
    border-radius: var(--radius-md);

    @media (--screen-lt-lg) {
        max-inline-size: 100%;
    }
}
